import highchartsz from 'highcharts';
import jquery from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Provider } from 'react-redux';
import store from '../global/store/createStore';
import ActiveHeader from '../global/components/ActiveHeader';
import LoadingIndicator from '../global/components/LoadingIndicator';
import Axios from 'axios';
import { t } from '../global/locale';
import { ErrorPartialView } from '../global/ErrorView';

class RenderServerSidePage extends React.Component {
    state = { loading: true, html: null, js: null, error: null };
    static propTypes = {
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        sidebarBody: PropTypes.bool,
        location: PropTypes.object,
    };
    doRequest() {
        this.setState({ loading: true, html: null, js: null, error: null });
        Axios.get(this.props.url + this.props.location.search)
            .then(response => {
                let html = response.data;
                let js = '';
                const scriptMatches = html.matchAll(/<script.*>([\s\S]+?)<\/script>/gi);
                for (const scriptMatch of scriptMatches) {
                    html = html.replace(scriptMatch[0], '');
                    js += scriptMatch[1];
                }
                this.setState({ loading: false, html, js });
            })
            .catch(e => {
                this.setState({ loading: false, error: t('Kunne ikke laste: ') + this.props.title });
                throw e;
            });
    }
    componentDidMount() {
        this.doRequest();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.url !== this.props.url || this.props.location.search !== prevProps.location.search) {
            this.doRequest();
        } else if (this.state.js && this.state.js !== prevState.js) {
            // we need to make highcharts available to the evaled code
            // and we cannot just import it,, this works in dev, hopefully it still works in prod. maybe it won.. well see
            var $ = jquery;
            var highcharts = highchartsz;
            eval(this.state.js);
        }
    }
    renderBody() {
        if (this.state.error) {
            return <ErrorPartialView error={this.state.error} />;
        } else if (this.state.loading) {
            return <LoadingIndicator/>;
        } else if (this.props.sidebarBody) {
            return <div className="layout-with-sidebar-body" dangerouslySetInnerHTML={{ __html: this.state.html }} />;
        } else {
            return <div dangerouslySetInnerHTML={{ __html: this.state.html }} />;
        }
    }
    render() {
        return (
            <div className="layout-with-sidebar">
                <Provider store={store}>
                    <ActiveHeader title={this.props.title} />
                </Provider>
                {this.renderBody()}
            </div>
        );
    }
}
export default withRouter(RenderServerSidePage);
